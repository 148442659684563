import { ReactComponent as Computer } from "../assets/computer.svg";
import { ReactComponent as Learn } from "../assets/learn.svg";
import { ReactComponent as Gift } from "../assets/gift.svg";

const About = () => {
  return (
    <div className="max-w-screen-xl lg:mx-auto my-10 px-8 font-primaryFont font-light">
      <div className=" flex flex-col justify-center items-center">
        <h1 className="text-2xl text-center">
          A one-stop interactive personal finance learning mobile app platform
        </h1>
        <a className="mt-4 rounded px-4 py-2 bg-yellow-400 border border-black" href="https://linktr.ee/vespidmy" target="_blank" rel="noreferrer">Beta App </a>
      </div>
      <div className="mt-20 flex flex-col md:flex-row md:justify-center items-center">
        <Computer className="w-1/2 md:w-4/12" />
        <div className="mt-4 md:w-1/2 md:px-20">
          <h2 className="text-xl text-center">
          Consolidated platform for all personal finance related topics
          </h2>
          <p className="mt-2 font-light text-center">
            Vespid has simplistic app design and intuitive user experience for
            beginner and experienced users
          </p>
        </div>
      </div>
      <div className="mt-10 flex flex-col md:flex-row-reverse md:justify-center items-center">
        <Learn className="w-1/2 md:w-4/12" />
        <div className="md:w-1/2 mt-4 md:px-20">
          <h2 className="text-xl text-center">
          Simplified, multilingual and gamified learning process
          </h2>
          <p className="mt-2 font-light text-center">
            Learn personal finance with bite-sized content, quizzes, leaderboard, and rewards 
          </p>
        </div>
      </div>
      <div className="mt-10 flex flex-col md:flex-row md:justify-center items-center">
        <Gift className="w-1/2 md:w-4/12" />
        <div className="md:w-1/2 mt-4 md:px-20">
          <h2 className="text-xl text-center">
            Complete quizzes to qualify for rewards and rebates
          </h2>
          <p className="mt-2 font-light text-center">
            Participate in quizzes on our Instagram page and stand a chance to
            win FREE vouchers and rebates. Follow us on our Instagram page
            <a
              href="https://www.instagram.com/vespid_my/"
              target="_blank"
              rel="noreferrer noopener"
              className=" text-primaryOrange"
            >
              {" "}
              @vespid_my
            </a>{" "}
            to begin participating!
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
