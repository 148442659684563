import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoLogoFacebook, IoLogoInstagram } from "react-icons/io5";

const Hamburger = ({ setOpenMenu }) => {
  const hamburgerRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (hamburgerRef.current && !hamburgerRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hamburgerRef,setOpenMenu]);

  return (
    <div ref={hamburgerRef} className="rounded-lg border-2 border-primaryOrange bg-gray-200 bg-opacity-95 py-5 px-4 z-50 fixed bottom-20 right-20 mr-4 w-60 font-light">
      <button
        onClick={() => setOpenMenu(false)}
        className="absolute right-2 rounded-full bg-primaryOrange text-sm px-2"
      >
        x
      </button>

      <div className="flex flex-col text-xl text-center">
        <Link
        id="menu-home"
          to="/home"
          className="my-2 hover:underline"
        >
          Home
        </Link>
        <Link
        id="menu-about"
          to="/about"
          className="my-2 hover:underline"
        >
          About
        </Link>
        <Link
        id="menu-contact"
          to="/contact"
          className="my-2 hover:underline"
        >
          Contact
        </Link>
        <div className="flex flex-row justify-center my-2"><a
            href="https://www.instagram.com/vespid_my/"
            target="_blank"
            rel="noreferrer noopener"
            className="mx-2"
          >
            <IoLogoInstagram className="w-6 h-6 inline"/>
          </a>
          <a href="https://www.facebook.com/vespid.my" target="_blank" rel="noreferrer noopener" className="mx-2">
          <IoLogoFacebook className="w-6 h-6 inline"/>
            </a></div>
        
      </div>
    </div>
  );
};

export default Hamburger;
