import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { contextClass } from "./core/notification_handler";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Contact from "./screens/Contact";
import About from "./screens/About";
import Privacy from "./screens/Privacy";

const Routers = () => (
  <BrowserRouter>
    <Header></Header>
    <ToastContainer
      toastClassName={({ type }) =>
        contextClass[type || "default"] +
        " text-black relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
      }
      bodyClassName={() =>
        "my-4 flex flex-row text-sm font-primaryFont text-black"
      }
    />
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route exact path="/home" element={<App />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/privacy" element={<Privacy />}></Route>
    </Routes>
    <Footer />
  </BrowserRouter>
);

ReactDOM.render(
  <React.StrictMode>
    <Routers />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
