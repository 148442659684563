import { useState } from "react";
import { Link } from "react-router-dom";
import { IoMenuOutline, IoLogoInstagram, IoLogoFacebook } from "react-icons/io5";
import LazyLoad from 'react-lazyload';
import horizontalLogo from "../assets/vespidHorizontalLogo.png";
import Hamburger from "./Hamburger";

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div className="py-5 px-16 flex flex-row justify-center md:justify-between font-primaryFont">
      <div className="w-32">
        <Link to="/home">
          <LazyLoad>
          <img src={horizontalLogo} alt="Vespid logo" loading="lazy"/>
          </LazyLoad>
        </Link>
      </div>
      <div className="z-50 block fixed bottom-24 right-5 md:hidden">
        {openMenu ? <Hamburger setOpenMenu={setOpenMenu} /> : undefined}
        <button
          onClick={() => setOpenMenu(!openMenu)}
          className="rounded-full bg-primaryYellow bg-opacity-80 p-3"
        >
          <IoMenuOutline size="30" />
        </button>
      </div>
      <div className="hidden md:block">
        <Link
          to="/"
          className="mx-6 font-light md:text-xl hover:border-b hover:border-primaryYellow"
        >
          Home
        </Link>
        <Link
          to="/about"
          className="mx-6 font-light md:text-xl hover:border-b hover:border-primaryYellow"
        >
          About
        </Link>
        <Link
          to="/contact"
          className="mx-6 font-light md:text-xl hover:border-b hover:border-primaryYellow"
        >
          Contact
        </Link>
        <a
            href="https://www.instagram.com/vespid_my/"
            target="_blank"
            rel="noreferrer noopener"
            className="mx-6"
          >
            <IoLogoInstagram className="w-6 h-6 inline"/>
          </a>
          <a href="https://www.facebook.com/vespid.my" target="_blank" rel="noreferrer noopener" className="mx-2">
          <IoLogoFacebook className="w-6 h-6 inline"/>
            </a>
          
      </div>
    </div>
  );
};

export default Header;
